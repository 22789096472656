<template>
  <v-row
    class="fill-height pt-0 white--text pb-16 mt-10 contactForm"
    justify="center"
  >
    <v-col
      cols="12"
      md="6"
      class="d-flex flex-column justify-center my-auto"
      :class="$vuetify.breakpoint.smAndDown ? 'pb-16 mt-16' : 'px-16'"
    >
      <v-form
        v-model="valid"
        :class="$vuetify.breakpoint.smAndDown ? 'pl-6 pr-9' : ''"
      >
        <v-row>
          <v-col class="d-flex align-baseline justify-space-between pb-0 pr-0">
            <h2 class="display-1 mb-3">Project Details</h2>
            <v-switch
              v-model="project.published"
              label="Published"
              dark
              color="primary"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" class="pr-0">
            <v-text-field
              v-model="project.name"
              label="Project Name"
              hide-details="auto"
              outlined
              dark
            />
          </v-col>
          <v-col cols="12" md="6" class="pr-0">
            <v-text-field
              v-model="project.client"
              label="Client"
              hide-details="auto"
              outlined
              dark
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" class="pr-0">
            <v-text-field
              v-model="project.location"
              label="Location"
              hide-details="auto"
              outlined
              dark
            />
          </v-col>
          <v-col cols="12" md="6" class="pr-0">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="project.dateCompleted"
                  label="Completion Date"
                  readonly
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                  dark
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="project.dateCompleted"
                @input="menu = false"
                color="grey darken-2"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" class="pr-0">
            <v-select
              v-model="project.tag"
              label="Project Type *"
              hide-details="auto"
              :rules="typeRules"
              outlined
              :items="types"
              item-value="value"
              item-text="text"
              :menu-props="{ bottom: true, offsetY: true }"
              dark
            />
          </v-col>
          <v-col cols="12" md="6" class="pr-0">
            <v-select
              v-if="project.tag == 'architectural-woodwork'"
              v-model="project.category"
              label="Category"
              hide-details="auto"
              :rules="categoryRules"
              outlined
              :items="category"
              item-value="value"
              item-text="text"
              :menu-props="{ bottom: true, offsetY: true }"
              dark
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pr-0">
            <v-textarea
              v-model="project.description"
              label="Description"
              hide-details="auto"
              outlined
              dark
            />
            <v-btn
              color="primary"
              :disabled="!valid"
              width="100%"
              class="mt-6"
              outlined
              rounded
              dark
              :loading="addLoad"
              @click="updateRecords()"
            >
              {{ $route.params.id ? "Update" : "Submit" }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <v-col
      cols="12"
      md="6"
      class="d-flex flex-column justify-center px-16"
      :class="$vuetify.breakpoint.smAndDown ? 'pb-16' : 'pt-16 mt-10'"
    >
      <h2 class="title">Featured Image</h2>
      <v-card
        width="150"
        height="150"
        @click="onPickFile('featured')"
        class="mb-2 d-flex justify-center align-center"
        dark
        flat
        :color="project.images.featured == null ? 'transparent' : ''"
      >
        <v-icon v-if="!isImage" size="60"> mdi-plus </v-icon>
        <v-img
          v-else
          max-height="150"
          contain
          :src="!isImage ? '' : project.images.featured.temp"
        />
      </v-card>
      <input
        type="file"
        style="display: none"
        ref="featured"
        accept="image/*"
        @change="onFilePicked"
      />
      <h2 class="title">Gallery Image</h2>
      <div class="d-flex flex-wrap">
        <div v-for="(item, i) in project.images.gallery" :key="item.i">
          <v-card
            width="150"
            height="150"
            class="mb-2 mr-2 d-flex justify-center align-center"
            color="transparent"
            flat
          >
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-img
                max-height="150"
                :src="
                  item.temp != undefined && item.temp != null && item.temp != ''
                    ? item.temp
                    : ''
                "
                contain
              >
                <v-overlay absolute :opacity="hover ? 0.6 : 0">
                  <v-icon v-if="hover" @click="removeImage(item, i)">
                    mdi-delete
                  </v-icon>
                </v-overlay>
              </v-img>
            </v-hover>
          </v-card>
        </div>
        <div>
          <v-card
            width="150"
            height="150"
            class="mb-2 d-flex justify-center align-center"
            dark
            flat
            @click="onPickFile('gallery')"
          >
            <v-icon size="60">mdi-plus</v-icon>
          </v-card>
          <input
            type="file"
            style="display: none"
            multiple
            ref="gallery"
            accept="image/*"
            @change="onFilePicked"
          />
        </div>
      </div>

      <v-dialog
        v-model="dialog"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card>
          <!-- <v-toolbar color="primary" dark>Remove Image</v-toolbar> -->
          <v-card-text>
            <div class="pa-12">
              Are you sure you want to remove this image? It will be removed
              permanently.
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialog = false">Close</v-btn>
            <v-btn text @click="deleteImage">Remove</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar v-model="snackbar">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
import { storage, projectsCollection } from "../firebase/credentials";
export default {
  data: () => ({
    valid: false,
    menu: false,
    selected: null,
    addLoad: false,
    dialog: false,
    tempImages: null,
    tempRemove: null,
    snackbar: false,
    snackText: '',
    typeRules: [(v) => !!v || "Type is required"],
    project: {
      published: false,
      id: null,
      name: "",
      dateCompleted: null,
      images: {
        featured: {
          file: null,
          path: null,
          temp: null,
        },
        gallery: [],
      },
    },
    types: [
      { text: "Commercial", value: "commercial" },
      { text: "Public Works", value: "public-works" },
      { text: "Residential", value: "residential" },
      { text: "Architectural Woodwork", value: "architectural-woodwork" },
    ],
    category: [
      { text: "Residential", value: "residential" },
      { text: "Commercial", value: "commercial" },
      { text: "Hospitality", value: "hospitality" },
      { text: "Medical / Institutional", value: "medical-institutional" },
    ],
    nameRules: [(v) => !!v || "Name is required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    messageRules: [(v) => !!v || "Message is required"],
    categoryRules: [(v) => !!v || "Category is required"],
  }),
  computed: {
    isImage() {
      let result = false;
      if (
        this.project.images.featured.temp == null &&
        this.project.images.featured.temp == undefined &&
        this.project.images.featured == null
      )
        result = false;
      else result = true;
      return result;
    },
  },
  methods: {
    onPickFile(value) {
      this.selected = value;
      this.$refs[value].click();
    },
    onFilePicked(event) {
      Array.from(event.target.files).forEach((file) => {
        let fileName = file.name;
        if (fileName.lastIndexOf(".") <= 0) {
          return alert("Please add a valid image.");
        }

        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          if (this.selected == "featured") {
            this.project.images.featured = {
              temp: fileReader.result,
              file: file,
              path: `/uploads/projects/${file.name}`,
            };
          } else {
            this.project.images.gallery.push({
              temp: fileReader.result,
              file: file,
              path: `/uploads/projects/${file.name}`,
            });
          }
        });
        fileReader.readAsDataURL(file);
      });
    },
    removeImage(image, i) {
      if (!this.$route.params.id) {
        this.project.images.gallery.splice(i, 1);
      } else {
        this.tempRemove = image.path;
        this.selectedImg = image.temp;
        this.dialog = true;
      }
    },
    async deleteImage() {
      await projectsCollection.doc(this.$route.params.id).update({
        images: this.tempImages.filter((img) => img != this.tempRemove),
      });
      const desertRef = storage.refFromURL(this.selectedImg);
      desertRef
        .delete()
        .then(() => {
          this.dialog = false;
          this.snackbar = true;
          console.log("deleted");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async uploadImages() {
      try {
        if (this.project.images.gallery.length > 0) {
          await this.project.images.gallery.map(async (e) => {
            if (e.file != undefined) storage.ref(e.path).put(e.file);
          });
        }
        if (this.project.images.featured.path != null) {
          if (this.project.images.featured.file != undefined)
            await storage
              .ref(this.project.images.featured.path)
              .put(this.project.images.featured.file);
        }
      } catch (err) {
        console.log("image add error", err);
      }
    },
    async updateRecords() {
      this.addLoad = true;

      if (this.valid)
        try {
          let data = {
            client: this.project.client || null,
            dateCompleted: this.project.dateCompleted || null,
            description: this.project.description || null,
            location: this.project.location || null,
            name: this.project.name || null,
            tag: this.project.tag || null,
            images: this.project.images.gallery.map((e) => e.path),
            featured: this.project.images.featured.path,
            published: this.project.published,
            category: this.project.category || null,
          };
          let id;
          if (
            this.$route.params.id != null &&
            this.$route.params.id != undefined
          ) {
            try {
              await projectsCollection
                .doc(this.$route.params.id)
                .update(data)
                .then(async () => {
                  this.uploadImages(data);
                  this.addLoad = false;
                this.snackText = 'Project Updated';
                this.snackbar = true
                });
            } catch (error) {
              console.log(error);
            }
          } else {
            try {
              await projectsCollection.add(data).then(async (docRef) => {
                id = docRef.id;
                this.uploadImages();
                this.addLoad = false;
                this.snackText = 'Project Added';
                this.snackbar = true
              });
            } catch (error) {
              console.log(error);
            }
            this.project = id;
            this.$router.push(`/project-edit/${id}`);
          }
        } catch (error) {
          console.log(error);
        }
    },
  },
  async mounted() {
    if (this.$route.params.id) {
      let result = await projectsCollection.doc(this.$route.params.id);
      result.onSnapshot(async (a) => {
        this.tempImages = await a.data().images;
        let images = a.data().images;
        let gallery = [];
        if (images != undefined && images != null) {
          for (let i = 0; i < images.length; i++) {
            gallery.push({
              path: images[i],
              temp: await storage.ref().child(images[i]).getDownloadURL(),
            });
          }
        }
        this.project = await {
          client: a.data().client,
          dateCompleted: a.data().dateCompleted,
          description: a.data().description,
          location: a.data().location,
          name: a.data().name,
          tag: a.data().tag,
          published: a.data().published,
          category: a.data().category,
          images: {
            featured:
              a.data().featured != undefined
                ? {
                    path: a.data().featured,
                    temp: await storage
                      .ref()
                      .child(a.data().featured)
                      .getDownloadURL(),
                  }
                : {
                    path: "",
                    temp: "",
                  },
            gallery: gallery,
          },
        };
        this.addLoad = false;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.contactForm {
  padding-top: 150px;
  min-height: 80vh;
}
</style>
